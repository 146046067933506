import React from "react"
import {
    Button,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
  } from 'semantic-ui-react'

import { Link } from "gatsby"
import PropTypes from "prop-types"


import B2BLogo from "@images/B2BIntegratorLogo.svg"


const HeaderRow = ({ data }) => {
  const [visible, setVisible] = React.useState(false)
  const sayHello = () => {
      setVisible(!visible);
    };

  return (
  <Grid.Row columns={2}>
      <Grid.Column mobile={14} computer={8}>
          <Image src={B2BLogo} as='a' href='/'/>
      </Grid.Column>
      <Grid.Column only="computer" computer={8}>
          <Menu secondary>
              <Menu.Item as={Link} name='features'  to='/features'>Features</Menu.Item>
              <Menu.Item as={Link} name='pricing'  to='/pricing'>Pricing</Menu.Item>
              <Menu.Item name='resources'>Resources</Menu.Item>
              <Menu.Menu position="right">
                  <Menu.Item as={Link} name='login' to='/login'>Login</Menu.Item>
              </Menu.Menu>
          </Menu>
      </Grid.Column>
      <Grid.Column only="tablet mobile" mobile={2}>
          <Menu secondary floated='right' >
              <Menu.Item >
                  <Icon onClick={() => {sayHello()}} name="sidebar"></Icon>
              </Menu.Item>
          </Menu>
      </Grid.Column>
  </Grid.Row>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default HeaderRow
